import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import Link from 'next/link';
import { useFilmmakers } from '@bamboo/core-lib/src/repo/filmmaker';
import type { FilmmakerProfile } from '@bamboo/core-lib/src/api/types';

const Root = styled('section')(({ theme }) => ({
  display: 'flex',
  flex: '4',
  flexWrap: 'wrap',
  boxSizing: 'border-box',
  position: 'relative',
  width: '100%',
  padding: '0px 0px 0px 7px',
  marginTop: '4%',

  [theme.breakpoints.down('lg')]: {
    marginBottom: 25,
    alignSelf: 'center',
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    padding: '0 25px 4px 25px',
    alignSelf: 'center',
    maxWidth: 450,
    marginBottom: 18,
  },
}));

const TextContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  minHeight: '1px',
  position: 'relative',
  width: '42%',
  padding: '18px 105px 18px 18px',
  alignContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('xl')]: {
    paddingRight: '15px',
  },
  [theme.breakpoints.down('lg')]: {
    width: '100%',
    paddingRight: '0px',
    marginRight: '50px',
    alignSelf: 'center',
    justifySelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    justifyItems: 'center',
  },
  [theme.breakpoints.down('md')]: {
    padding: '0px 0px 18px',
    alignItems: 'flex-start',
    marginRight: '0px',
    margin: 18,
    width: 'auto',
  },
}));

const TitleContainer = styled('div')(({ theme }) => ({
  width: '100%',
  textAlign: 'left',
  marginBottom: '0px',
  transition:
    'background 0.3s ease 0s, border 0.3s ease 0s, border-radius 0.3s ease 0s, box-shadow 0.3s ease 0s, -webkit-border-radius 0.3s ease 0s, -webkit-box-shadow 0.3s ease 0s',
  margin: '0% 0% 5%',
  padding: '0px',
}));

const SectionTitle = styled('div')(({ theme }) => ({
  boxSizing: 'border-box',
  width: '100%',
  margin: '0px 0px 10px',
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

const Title = styled('h2')(({ theme }) => ({
  padding: '0px',
  margin: '0px',
  marginTop: '0px',
  marginBottom: '0px',
  color: 'rgb(58, 58, 58)',
  fontWeight: 500,
  lineHeight: '30px',
  fontSize: '1.6em',
  [theme.breakpoints.down('md')]: {
    marginTop: 0,
    marginBottom: 8,
    boxSizing: 'border-box',
    position: 'relative',
    width: '100%',
    color: 'rgb(0, 0, 0)',
    fontWeight: 600,
    fontSize: '30px',
    lineHeight: '1.1em',
    whiteSpace: 'pre-line',
  },
}));

const DescriptionContainer = styled('div')(({ theme }) => ({
  boxSizing: 'border-box',
  position: 'relative',
  color: '#727272',
  width: '100%',
  lineHeight: 1.5,
  textAlign: 'left',
  fontWeight: 400,
  fontSize: '14px',
  marginBottom: '0px',
}));

const Description = styled('p')(({ theme }) => ({
  marginTop: '0px',
  marginBottom: '0.9rem',
  [theme.breakpoints.down('md')]: {
    color: 'rgb(114, 114, 114)',
    fontSize: '14px',
    fontWeight: 400,
    marginTop: '0px',
    marginBottom: '0.9rem',
  },
}));

const ButtonContainer = styled('div')(({ theme }) => ({
  textAlign: 'left',
  position: 'relative',
  alignSelf: 'flex-start',
  [theme.breakpoints.down('lg')]: {
    marginBottom: '40px',
  },
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const ButtonLink = styled(Link)(({ theme }) => ({
  boxSizing: 'border-box',
  transition: 'all 0.3s ease 0s',
  display: 'flex',
  flex: '4',
  lineHeight: 1,
  fontSize: '15px',
  textAlign: 'center',
  textDecoration: 'none',
  boxShadow: 'none',
  width: 'auto',
  fontWeight: 500,
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: 'rgb(49, 49, 49)',
  borderRadius: '100px',
  padding: '7px 40px',
  fill: 'rgb(69, 69, 69)',
  color: 'rgb(69, 69, 69)',
  backgroundColor: 'rgba(255, 153, 102, 0)',
  [theme.breakpoints.down('lg')]: {
    padding: '15px 060px 15px 60px',
  },
}));

const ListContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  minHeight: '300px',
  display: 'flex',
  flex: '4',
  width: '65%',
  flexWrap: 'wrap',
  margin: '0px 10px 0px 0px',
  alignContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('lg')]: {
    marginRight: '60px',
    minHeight: '400px',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    marginRight: 0,
  },
}));

const List = styled('div')(({ theme }) => ({
  placeContent: 'center flex-start',
  display: 'flex',
  flex: '2',
  justifyContent: 'flex-start',
  textAlign: 'left',
  minHeight: '300px',
  [theme.breakpoints.down('lg')]: {
    minHeight: '400px',
    width: '100%',
  },
  [theme.breakpoints.down('md')]: {
    flex: '1',
    padding: '0',
    minHeight: '350px',
  },
}));

const ListItem = styled('div')(({ theme }) => ({
  display: 'flex',
  paddingLeft: '8px',
  paddingRight: '8px',
  paddingBottom: '0px',
  boxSizing: 'border-box',
  flex: '0 1 calc(100% / 4 - 16px)',
  flexGrow: 1,
  minHeight: '300px',
  [theme.breakpoints.down('lg')]: {
    minHeight: '400px',
  },
  [theme.breakpoints.down('md')]: {
    flex: '1',
    padding: '0',
    minHeight: '350px',
  },
}));

const FilmmakerLink = styled(Link)(({ theme }) => ({
  flex: 1,
  textDecoration: 'none',
  minHeight: '300px',
  [theme.breakpoints.down('lg')]: {
    minHeight: '400px',
  },
}));

const FilmmakerImage = styled(
  (props: React.HTMLProps<HTMLDivElement> & { src: string }) => {
    const { src, ...others } = props;
    return <div {...others} />;
  }
)(({ src, theme }) => ({
  backgroundImage: `url("${src}")`,
  aspectRatio: '9/16',
  borderStyle: 'none',
  boxSizing: 'border-box',
  border: 'none',
  verticalAlign: 'middle',
  borderRadius: '15px',
  display: 'block',
  height: 'auto',
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  boxShadow: 'rgba(152, 152, 152, 0.5) -10px 10px 18px 0px',
  width: '100%',
  maxWidth: '200px',
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
  },
}));

const FilmmakerDescription = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  marginTop: '13px',
  marginBottom: '0px',
  [theme.breakpoints.down('md')]: {
    zIndex: 1,
    width: '60%',
    position: 'absolute',
    top: '52px',
    fontSize: '9px',

    fontWeight: 500,
    padding: 'inherit',
    boxSizing: 'content-box',
    left: '35px',
  },
  [theme.breakpoints.down('sm')]: {
    zIndex: 1,
    position: 'absolute',
    top: '52px',
    fontSize: '9px',

    fontWeight: 500,
    padding: 'inherit',
    boxSizing: 'content-box',
    left: '35px',
  },
}));

const DisplayName = styled('div')(({ theme }) => ({
  padding: '0px 0px 0px 5px',
  fontSize: '11px',
  fontWeight: 700,
  color: 'rgb(87, 87, 87)',
  [theme.breakpoints.down('md')]: {
    boxSizing: 'border-box',
    color: 'rgb(255, 255, 255)',
    fontSize: '32px',
    textTransform: 'capitalize',
    lineHeight: '1em',
    letterSpacing: '-2px',
  },
}));

const SecondText = styled('div')(({ theme }) => ({
  padding: '0px 0px 0px 5px',
  color: 'rgb(87, 87, 87)',
  fontWeight: 'normal',
  fontSize: '10px',
  [theme.breakpoints.down('md')]: {
    boxSizing: 'border-box',
    fontSize: '16px',
    color: 'rgb(255, 255, 255)',
    lineHeight: '1em',
  },
}));

const Clips = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  fontSize: '9px',
  marginLeft: '5px',
  fontWeight: 500,
  color: 'rgb(255, 163, 117)',
  [theme.breakpoints.down('md')]: {
    fontSize: '10px',
  },
}));

const Details = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    height: '2.5em',
  },
}));

const DefaultLabels = {
  title: `100% dos nossos filmmakers são brasileiros. Explore centenas de clipes`,
  shortTitle: 'Nosso time de filmmakers é 100% brasileiro',
  description:
    'Com uma curadoria impecável, e imagens cinematográficas, representamos os mais talentosos filmmakers brasileiros. Comece a ter orgulho das suas produções com banco de imagens, com a segurança de que todas as imagens são de criadores nacionais.',
  buttonLabel: 'Comece a pesquisar',
  filmmakerClips: (n: number) => `${n} clipes`,
};

const useCalculateFilmmakerList = (filmmakers: FilmmakerProfile[]) => {
  const [filmmakerLimit, setFilmmakerLimit] = React.useState(filmmakers.length);
  const theme = useTheme();

  const isSmallerScreen = useMediaQuery(theme.breakpoints.down('md'), {
    noSsr: true,
  });

  const isMediumScreen = useMediaQuery(theme.breakpoints.down('lg'), {
    noSsr: true,
  });

  const isLargeScreen = useMediaQuery(theme.breakpoints.down('xl'), {
    noSsr: true,
  });
  React.useEffect(() => {
    if (isSmallerScreen) {
      setFilmmakerLimit(1);
      return;
    }
    if (isLargeScreen) {
      setFilmmakerLimit(3);
      return;
    }
    setFilmmakerLimit(4);
  }, [isSmallerScreen, isMediumScreen, isLargeScreen]);
  const list = React.useMemo(() => {
    return filmmakers.slice(0, filmmakerLimit);
  }, [filmmakers, filmmakerLimit]);

  return [list];
};

export type FilmmakersSummaryProps = {
  labels?: {
    title?: string;
    shortTitle?: string;
    description?: string;
    buttonLabel?: string;
    filmmakerClips?: (n: number) => string;
  };
};
const FilmmakersSummary = (props: FilmmakersSummaryProps) => {
  const { labels = DefaultLabels } = props;
  const { filmmakerBySlug, filmmakersListed } = useFilmmakers();
  const [isSM, setIsSM] = React.useState(false);
  const theme = useTheme();

  const isSmallerScreen = useMediaQuery(theme.breakpoints.down('md'), {
    noSsr: true,
  });

  const filmmakers = React.useMemo(() => {
    return filmmakersListed
      .map((slug) => filmmakerBySlug[slug])
      .filter((f) => !!f) as FilmmakerProfile[];
  }, [filmmakerBySlug, filmmakersListed]);

  const [list] = useCalculateFilmmakerList(filmmakers);

  React.useEffect(() => {
    setIsSM(isSmallerScreen);
  }, [isSmallerScreen]);

  return (
    <Root>
      <TextContainer>
        <SectionTitle>
          <h2
            style={{
              boxSizing: 'border-box',
              padding: 0,
              margin: 0,
              lineHeight: 1,
              color: 'rgb(0, 0, 0)',
              fontFamily: 'inherit',
              fontWeight: 400,
              fontSize: '13px',
              alignSelf: 'center',
            }}
          >
            Filmmakers
          </h2>
        </SectionTitle>
        <TitleContainer>
          <Title>{isSM ? labels?.shortTitle : labels?.title}</Title>
        </TitleContainer>
        <DescriptionContainer>
          <Description>{labels?.description}</Description>
        </DescriptionContainer>
        <ButtonContainer>
          <ButtonLink href="/search">
            <span
              style={{
                boxSizing: 'border-box',
                display: 'flex',
                WebkitBoxPack: 'center',
                justifyContent: 'center',
                textDecoration: 'inherit',
                justifySelf: 'center',
                alignSelf: 'center',
              }}
            >
              {labels?.buttonLabel}
            </span>
          </ButtonLink>
        </ButtonContainer>
      </TextContainer>
      <ListContainer>
        <List>
          {list.map((fm) => (
            <ListItem key={fm?.slug}>
              <FilmmakerLink href={`/filmmaker/${fm?.slug ?? ''}`}>
                <FilmmakerImage src={fm?.avatar ?? ''} />
                <FilmmakerDescription>
                  <DisplayName>
                    <span>{fm?.displayName}</span>
                  </DisplayName>
                  <Details>
                    {fm?.city ? <SecondText>{fm.city}</SecondText> : null}
                    <Clips>{labels?.filmmakerClips?.(fm?.clips)}</Clips>
                  </Details>
                </FilmmakerDescription>
              </FilmmakerLink>
            </ListItem>
          ))}
        </List>
      </ListContainer>
    </Root>
  );
};

export default FilmmakersSummary;
